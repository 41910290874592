.kpi-widget {
  border-radius: 16px;
  background-color: white;
  padding: 40px 0;
  border: 1px solid rgb(223, 226, 227);
  position: relative;
  margin-bottom: 12px;
}

.chart-widget-card-responsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: white;
  padding: 56px 0 24px 0;
  position: relative;
  border: 1px solid rgb(223, 226, 227);
  width: 100%;
  margin-bottom: 12px;
}

.chart-widget-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: white;
  padding: 56px 0 24px 0;
  position: relative;
  border: 1px solid rgb(223, 226, 227);
}

.chart-widget-card-header {
  position: absolute;
  top: 16px;
  left: 0;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-value {
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
  text-align: center;
}

.muted-text {
  color: #889da7;
}

.card-label {
  color: #889da7;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.card-question {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 50px;
}

.detail-row {
  display: grid;
  grid-template-columns: 1fr 80px 100px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 8px;
}

.progress-meter {
  width: 100%;
  height: 24px;
  background-color: #f4f7fd;
  border-radius: 4px;
}

.progress-bar {
  height: 24px;
  border-radius: 4px;
  background-color: #30b131;
}

.card-support {
  text-align: center;
  margin-bottom: 24px;
}

.comparative {
  position: relative;
  text-align: center;
}

.widget-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding-bottom: 16px;
}

.delta-inc {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: -4px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #30b131;
  transform: translateX(-250%);
}

.delta-dec {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 8px;
  border-style: solid;
  border-width: 10px;
  border-color: rgba(213, 43, 30, 0.8) transparent transparent;
  transform: translateX(-250%);
}

.span-two-cols {
  grid-column: 1 / 3;
}
