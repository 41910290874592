.filter-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 320px;
  top: 0;
  right: -100%;
  height: 100vh;
  z-index: 100;
  background-color: #FFFFFF;
  transition: right 800ms ease;
}

.filter-bar.open {
  right: 0;
  box-shadow: -3px 0 8px 0 rgb(66 91 118 / 21%);
  transition: right 400ms ease;
}

.filter-bar-header {
  padding: 16px;
  border-bottom: 1px solid #dfe2e3;
  position: relative;
}

.filter-bar-header h3 {
  line-height: 1;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
}

.filter-bar-body {
  padding: 16px;
  flex: 1;
  overflow: scroll;
}

.filter-bar-close-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 200ms ease;
  transition: color 100ms ease;
  position: absolute;
  top: 16px;
  right: 16px;
}

.filter-bar-close-button:hover {
  color: #FFFFFF;
  background-color: var(--color-primary);
}

.filter-bar-footer {
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.filter-bar-primary-button {
  background-color: var(--color-primary);
  color: #FFFFFF;
  text-align: center;
  font-size: 16px;
  line-height: 1;
  padding: 12px;
  width: 200px;
  display: block;
  border-radius: 16px;
}

.filter-bar-primary-button:hover {
  filter: brightness(120%);
}

.filter-bar-icon-button {
  background-color: #DFE2E3;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 200ms ease;
  transition: color 100ms ease;
}

.filter-bar-icon-button:hover {
  background-color: #0388D1;
  color: #FFFFFF;
}

/* Filtros disponibles */

.available-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.available-filters-title {
  font-size: 12px;
  font-weight: 600;
  flex-basis: 100%;
  margin-bottom: 8px;
}

.available-filters-pill {
  background-color: #DFE2E3;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 10px;
  color: #001724;
  line-height: 1;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: normal;
}
.available-filters-pill:hover {
  background-color: #0388d1;
  color: #FFFFFF;
}

/* React Multiselect Overrides */

.rmsc .options {
  max-height: 480px;
}