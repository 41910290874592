.banner-container {
  background-color: white;
  border-radius: 4px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-top: 12px;
}

.banner-title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.banner-text {
  font-size: 12px;
  line-height: 16px;
}

.banner-button {
  padding: 4px 12px;
  border-radius: 4px;
  background-color: #0388d1;
  color: white;
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  margin-left: 8px;
}

.table-link-button {
  padding: 4px 0px;
  color: #0388d1;
  background-color: transparent;
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  margin-left: 8px;
}
