.integration-card {
  padding: 16px 24px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #DFE2E3;
  position: relative;
  font-size: 12px;
  line-height: 20px;
}
.integration-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
}
.integration-text {
  font-size: 14px;
  max-width: 440px;
}
.integration-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}
.integration-button {
  display: block;
  padding: 8px 16px;
  border-radius: 12px;
  background-color: #373f46;
  color: #fff;
  text-align: center;
  margin-left: 12px;
}
.integration-explorer {
  max-width: 1024px;
}
.integration-list {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  row-gap: 16px;
}
.message-status {
  position: absolute;
  right: 24px;
  top: 8px;
}
.current-date {
  margin: 0.75rem auto auto auto;
  width: fit-content;
  height: 1.125rem;
  line-height: 1.125rem;
  background: var(--sib-color_surface-hover-background);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  font-size: 0.75rem;
  text-align: center;
  padding: 0px 12px;
}
.content-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1.25rem;
}
.bubble-arrow {
  position: relative;
  margin-top: -2.5rem;
  margin-left: -0.85rem;
}
.bubble-arrow:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid white;
  border-radius: 0 1.25rem 0;
  width: 0.7rem;
  height: 1.875rem;
  transform: rotate(45deg);
}
.message-area {
  position: relative;
  min-height: calc(100% - 25rem);
  background-color: white;
  border-radius: 0.375rem;
}
.separator {
  border: 0.005rem solid rgb(204, 204, 204);
  margin: 24px 0;
  width: 91%;
}
.icon-extlink {
  height: 0.9rem;
  width: 0.9rem;
  margin-top: 0.4rem;
  margin-right: 0.4rem;
  color: #0092ff;
}