.basic-score-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
}

.basic-score-meta {
  font-size: 10px;
  line-height: 16px;
}

.basic-score-label {
  font-size: 12px;
  line-height: 16px;
}

.basic-score-result {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}