.selected-filter {
  padding: 8px 12px;
  border: 1px solid #dfe2e3;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 8px;
}

.selected-filter:hover {
  background-color: #dfe2e3;
}