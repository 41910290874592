.CalendarDay__selected_span {
  background: #0299EB;
  color: white; 
  border: 1px solid #0299EB
}
.CalendarDay__selected {
  background: #0288D1;
  color: white;
}
.CalendarDay__selected:hover {
  background: #02679E;
  color: white;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #02679E;
  color: white;
}
.DateInput_input {
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 12px;
  font-weight: 500;
  border: none;
}

.DateRangePicker {
  border-radius: 12px;
  border: 1px solid #dfe2e3;
}

.DateRangePickerInput {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.DateRangePickerInput, .DateInput {
  border-radius: 12px;
}

.DateInput_input__focused {
  border: none;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
background-color: #02679E;
}