.comment-backdrop {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  top: 100vh;
  left: 0;
  background: rgba(0,0,0,0.8);
  z-index: 100;
  overflow-y: scroll;
  padding: 64px;
  transition: top 0.3s;
}

.comment-backdrop.active {
  top: 0;
  transition: top 0.2s ease-out;
}

.comment-backdrop-close {
  color: #FFFFFF;
  position: fixed;
  top: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
}

.comment-backdrop .comment-backdrop-close {
  display: none;
}

.comment-backdrop.active .comment-backdrop-close {
  display: block;
}

.comment-list {
  padding-bottom: 24px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  row-gap: 16px;
}

.comment-list.standalone {
  width: 600px;
  max-width: 100%;
}

.comment-list.standalone .comment-card {
  pointer-events: none;
}

.comment-card {
  padding: 16px 24px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #DFE2E3;
  position: relative;
  font-size: 12px;
  line-height: 20px;
}

.comment-author {
  font-weight: 600;
}
.comment-text {
  font-size: 14px;
  max-width: 440px;
}

.comment-date {
  position: absolute;
  right: 24px;
  top: 36px;
}

.comment-meta {
  display: flex;
  column-gap: 8px;
  padding-top: 16px;
  padding-right: 200px;
  align-items: center;
}

.comment-tag {
  padding: 4px 8px;
  background-color: #DFE2E3;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
}

.comment-tag-not-found {
  padding: 4px 8px;
  background-color: #E86445;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  color: white;
}

.comment-tag.NEGATIVE {
  background-color: #FC0245;
  color: #ffffff;
}

.comment-tag.POSITIVE {
  background-color: #74E084;
  color: #ffffff;
}

.comment-tag.MIXED {
  background-color: #3AD0E3;
  color: #ffffff;
}

.comment-contact {
  margin-top: 8px;
  font-weight: 600;
  text-align: end;
}

.react-tags {
  position: relative;
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  padding: 4px 8px;
  background-color: #DFE2E3;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
}

.react-tags__selected-tag-verification {
  display: inline-block;
  padding: 4px 8px;
  background-color: #EBD746;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  padding: 4px 8px;
  background-color: #DFE2E3;
  border-radius: 2px;
  border: none;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  min-width: 300px;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 300px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.loader {
  width: 18px;
  height: 18px;
  border: 4px solid gray;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 15px;
  background-color: green;
  left: 11px;
  top: 0px;
}

.checkmark_kick {
  position: absolute;
  width: 8px;
  height: 3px;
  background-color: green;
  left: 6px;
  top: 14px;
}

.errorComment {
  color: red;
  margin-top: 8px;
}