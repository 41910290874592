.global-selector {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 92px;
  padding-right: 16px;
  margin-bottom: 24px;
  height: 48px;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  background-color: white;
  width: 440px;
  border-radius: 8px;
  /*margin-left: auto;*/
}

.standalone-selector {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 92px;
  /* padding-right: 16px; */
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}

.global-selector-label {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #001724; */
  width: 80px;
  color: #001724;
  font-size: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-weight: 600;
}

.global-selector-options {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 20px;
}

.global-selector-item {
  padding-left: 16px;
  color: #808080;
  text-transform: uppercase;
  position: relative;
  line-height: 1;
  cursor: pointer;
  font-size: 12px;
}

.global-selector-item:hover {
  color: #001724;
}

.global-selector-item.disabled:hover {
  color: #808080;
  cursor: progress;
}

.global-selector-item-dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #FEEDE6;
}

.global-selector-item.active {
  color: #001724;
}

.global-selector-item.active.disabled:hover {
  color: #001724;
  cursor: progress;
}

.global-selector-item.active > .global-selector-item-dot {
  background-color: #FC4C02;
}