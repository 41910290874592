.ticket-explorer {
  display: -ms-grid;
  display: grid;
  /* -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; */
  grid-column-gap: 24px;
  -ms-grid-columns: 3fr 1fr;
  grid-template-columns: 3fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-auto-flow: column;
}

.ticket-list {
  padding-bottom: 24px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  row-gap: 16px;
}

.ticket-card {
  padding: 16px 24px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #DFE2E3;
  position: relative;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}

.ticket-detail-card {
  padding: 16px 24px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #DFE2E3;
  position: relative;
  font-size: 12px;
  line-height: 20px;
  max-width: 1366px;
}

.message-card{
  margin: 12px 6px;
  padding: 14px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #DFE2E3;
  position: relative;
  font-size: 12px;
  line-height: 20px;
  max-width: 1024px;
}

.ticket-title {
  font-weight: 600;
}
.ticket-text {
  font-size: 14px;
  max-width: 440px;
}

.ticket-date {
  position: absolute;
  right: 24px;
  top: 36px;
}

.message-status {
  position: absolute;
  right: 24px;
  top: 8px;
}

.ticket-meta {
  display: flex;
  column-gap: 8px;
  padding-top: 16px;
  padding-right: 200px;
  align-items: center;
}

.ticket-tag {
  padding: 4px 8px;
  background-color: #DFE2E3;
  border-radius: 2px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
}

.ticket-contact {
  position: absolute;
  right: 24px;
  bottom: 16px;
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto;
  grid-auto-flow: column;
  column-gap: 8px;
  font-weight: 600;
}

.ticket-action {
  min-width: 140px;
  padding: 4px;
  font-size: 14px;
  background-color: var(--color-primary);
  color: #fff;
  margin-bottom: 12px;
  border-radius: 4px;
}