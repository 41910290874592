.invitation-explorer {
  display: -ms-grid;
  display: grid;
  grid-column-gap: 24px;
  -ms-grid-columns: 3fr 1fr;
  grid-template-columns: 3fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-auto-flow: column;
}

.invitation-list {
  padding-bottom: 24px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  row-gap: 16px;
}

.invitation-card {
  padding: 16px 24px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #DFE2E3;
  position: relative;
  font-size: 12px;
  line-height: 20px;
}

.invitation-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
}
.invitation-text {
  font-size: 14px;
  max-width: 650px;
}

.invitation-action {
  min-width: 140px;
  padding: 4px;
  font-size: 14px;
  background-color: var(--color-primary);
  color: #fff;
  margin-bottom: 12px;
  border-radius: 4px;
}

.invitation-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}

.invitation-status {
  position: absolute;
  right: 24px;
  top: 28px;
  font-weight: 600;
}

.invitation-button {
  display: block;
  padding: 8px 16px;
  border-radius: 12px;
  background-color: #373f46;
  color: #fff;
  text-align: center;
  margin-left: 12px;
}

.invitation-status {
  position: absolute;
  right: 24px;
  top: 8px;
}